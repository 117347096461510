import React, {useContext, useEffect, useState} from 'react';
// components
import logo from '../../assets/img/Logo-Wordmark.png';
import signin from '../../assets/img/planet.png';
import signinPlanStar from '../../assets/img/stars.png';
import Input from "../../components/Input/Input";
import {Link, useLocation, useNavigate} from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import {analytics, auth, db} from "../../firebase";
import {createUserWithEmailAndPassword, updateProfile} from "@firebase/auth";
import axios from 'axios';
import routes from "../../route";
import Bugsnag from "@bugsnag/js";
import {extMainApiUrl} from "../../firebase";
import {logEvent} from "firebase/analytics";
import {UserContext} from "../../hooks/useUser";
import Card from "../../components/Card/Card";
import Button from "../../components/Button/Button";
import PasswordStrengthBar from 'react-password-strength-bar';
import {stringDeleteSpace, stringReplaceDoubleSpaceBySimple} from "../../shared/utility";

export default function Signup() {

    const location = useLocation()
    const navigate = useNavigate()
    const {updateUser, fetchTokensBalances} = useContext(UserContext);
    const [fname, setFname] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [confirmedEmail, setConfirmedEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [referral, setReferral] = useState('');
    const [referralDisplay, setReferralDisplay] = useState('');
    const [passwordConf, setPasswordConf] = useState('');
    const [cgv, setCgv] = useState(false);

    const [validName, setvalidName] = useState(true);
    const [validFname, setvalidFname] = useState(true);
    const [validEmail, setvalidEmail] = useState(true);
    const [validPassword, setvalidPassword] = useState(true);
    const [validPasswordConf, setvalidPasswordConf] = useState(true);

    const [touchedName, settouchedName] = useState(false);
    const [touchedFname, settouchedFname] = useState(false);
    const [touchedEmail, settouchedEmail] = useState(false);
    const [touchedPassword, settouchedPassword] = useState(false);
    const [touchedPasswordConf, settouchedPasswordConf] = useState(false);

    const [cssName, setCssName] = useState('');
    const [cssFname, setCssFname] = useState('');
    const [cssEmail, setCssEmail] = useState('');
    const [cssPassword, setCssPassword] = useState('');
    const [cssPasswordConf, setCssPasswordConf] = useState('');

    const [errorMessageName, setErrorMessageName] = useState('');
    const [errorMessageFname, setErrorMessageFname] = useState('');
    const [errorMessageEmail, setErrorMessageEmail] = useState('');
    const [errorMessagePhone, setErrorMessagePhone] = useState('');
    const [errorMessagePassword, setErrorMessagePassword] = useState('');
    const [errorMessagePasswordConf, setErrorMessagePasswordConf] = useState('');
    const [errorRecaptcha, setErrorRecaptcha] = useState('');
    const [errorReferral, setErrorReferral] = useState("");
    const [savingReferral, setSavingReferral] = useState(false);

    const [isRecaptchaDone, setIsRecaptchaDone] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const [invitedBy, setInvitedBy] = useState('');

    const [parrain, setParrain] = useState({});
    const [passwordScore, setPasswordScore] = useState(0);

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('ref')) {
            setReferral(searchParams.get('ref'))
            localStorage.setItem('ref', searchParams.get('ref'));
        } else {
            setReferral(localStorage.getItem('ref'))
        }

    }, [location.search]);

    useEffect(() => {
        logEvent(analytics, 'start_signup')
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'signup',
            firebase_screen_class: 'DASHBOARD'
        });
    }, [])

    // useEffect(() => {
    //
    //     const unsubscribe = async () => {
    //         setErrorReferral("")
    //         if (referral !== '') {
    //             if (invitedBy === '' || invitedBy === null) {
    //                 if (referral && referral.length>=5) {
    //                     const url = `${extMainApiUrl}/getReferral2?referral_code=${referral}`
    //                     await axios.get(url).then( async res => {
    //                         const setData = async () => {
    //                             setErrorReferral("")
    //                             
    //                             if (res.data === 'user not found') {
    //                                 setErrorReferral("Referral not found")
    //                             } else {
    //                                 setErrorReferral("")
    //                                 setInvitedBy(res.data.fname)
    //                                 setParrain(res.data)
    //                             }
    //                         }
    //
    //                         await setData()
    //                     })
    //                 }
    //             }
    //         } else {
    //             setErrorReferral("")
    //             setInvitedBy("")
    //             setParrain({})
    //         }
    //     }
    //
    //     unsubscribe()
    //
    //     return () => {
    //         unsubscribe()
    //     }
    //
    // }, [referral]);

    useEffect(() => {
        setShowMessage(false)
        setInvitedBy("")
    }, [referral]);

    const makeid = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        // if (!isRecaptchaDone) {
        //     setErrorRecaptcha("Captcha is not completed")
        // } else 
        if (fname.length < 1 || fname === " ") {
            setErrorMessageFname("Firstname cannot be empty")
            setCssFname('border-red-300')
            setvalidFname(false)
            settouchedFname(true)
        } else if (name.length < 1 || name === " ") {
            setErrorMessageName("Name cannot be empty")
            setCssName('border-red-300')
            setvalidName(false)
            settouchedName(true)
        } else if (password !== passwordConf) {
            setErrorMessagePasswordConf("Password are not the same")
            setErrorMessagePassword("Password are not the same")

            setCssPassword('border-red-300')
            setCssPasswordConf('border-red-300')

            setvalidPassword(false)
            setvalidPasswordConf(false)

            settouchedPassword(true)
            settouchedPasswordConf(true)
        } else if (email !== confirmedEmail) {
            setErrorMessageEmail("Emails are not the same")
            setCssEmail('border-red-300')
            settouchedEmail(true)
            setvalidEmail(false)
        } else if (/[@]getimagine[.]org$/.test(email)) {
            setErrorMessageEmail("email ending by @getimagine.org is not authorized");
            setCssEmail('border-red-300');
            settouchedEmail(true);
            setvalidEmail(false);
        } else if (passwordScore < 3) {
            setErrorMessagePassword("Password not strong enough")
            setCssPassword('border-red-300');
            setvalidPassword(false)
            settouchedPassword(true)
        } else {
            await createUserWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {

                    logEvent(analytics, 'end_signup')
                    logEvent(analytics, 'sign_up')

                    await updateProfile(auth.currentUser, {
                        displayName: fname + ' ' + name,
                        phoneNumber: "+" + phone
                    })

                    const referralCode = makeid(5)

                    await auth.currentUser.getIdToken().then(async token => {

                        const url = `${db}Users/${userCredential.user.uid}.json?auth=${token}`
                        const newUser = {
                            role: 'user',
                            firstConnection: true,
                            invitationCode: referral,
                            cgv: 'avril 2021',
                            fname: fname + ' ' + name,
                            name,
                            userInvitationCode: referralCode,
                            referralRate: 10,
                            fromGame: false,
                            IDENTITY: "NOT SUBMITTED",
                            KYC_STATUS: "NOT SUBMITTED",
                            LIVENESS: "NOT SUBMITTED",
                            RESIDENCY: "NOT SUBMITTED",
                            subscriptionDate: new Date().toISOString(),
                            email: auth.currentUser.email,
                        }
                        await updateUser(newUser)
                        await fetchTokensBalances()
                    }).catch(err => Bugsnag.notify(err))

                    if (auth.currentUser.emailVerified) {
                        // TODO handle a list update on email verification
                        await axios.post(`${extMainApiUrl}/sendNotValidatedUserToSendinBlue`, {
                            email: auth.currentUser.email,
                            fname: fname,
                            name: name,
                            phone: phone,
                            userId: auth.currentUser.uid,
                            userInvitationCode: referralCode,
                        })

                    } else {
                        await axios.post(`${extMainApiUrl}/sendNotValidatedUserToSendinBlue`, {
                            email: auth.currentUser.email,
                            fname: fname,
                            name: name,
                            phone: phone,
                            userId: auth.currentUser.uid,
                            userInvitationCode: referralCode,
                        })
                    }

                    if (parrain && parrain.uid) {
                        await axios.post(`${extMainApiUrl}/sendHasReferralSendinblue`, {
                            referralId: auth.currentUser.uid,
                            uid: parrain.uid
                        })
                    }

                }).then(() => {
                    setTimeout(() => {
                        navigate(routes.DASHBOARD)
                    }, 500);
                }).catch((error) => {
                    switch (error.code) {
                        case "auth/email-already-in-use":
                            setErrorMessageEmail("Email is already in use. Connect directly")
                            setCssEmail('border-red-300')
                            setvalidEmail(false)
                            settouchedEmail(true)
                            break;
                        case "auth/weak-password":
                            setErrorMessagePassword("Password requires at least 6 caracters")
                            setCssPassword('border-red-300')
                            setvalidPassword(false)
                            settouchedPassword(true)
                            break;
                        default:
                            Bugsnag.notify(error)
                            setErrorMessageEmail("An error has occurred. Please contact support.")
                            setCssEmail('border-red-300')
                            setvalidEmail(false)
                            settouchedEmail(true)
                            break;
                    }
                });
        }
    }

    const handleRecaptcha = (value) => {
        setIsRecaptchaDone(true)
    }

    const handleOnPast = (e) => {
        e.preventDefault()
        setConfirmedEmail("")
    }

    const handleSaveReferralCode = async (tmpReferral) => {
        setErrorReferral("")
        setReferralDisplay(tmpReferral)
        setShowMessage(false)
        
        if (tmpReferral !== '') {
            // if (invitedBy === '' || invitedBy === null) {
                if (tmpReferral && tmpReferral.length >= 5) {
                    const url = `${extMainApiUrl}/getReferral2?referral_code=${tmpReferral}`
                    await axios.get(url).then(async res => {
                        const setData = async () => {
                            setErrorReferral("")
                            if (res.data.fname === 'user not found') {
                                setShowMessage(false)
                                setErrorReferral("Referral not found")
                                setInvitedBy("")
                                setParrain({})
                            } else {
                                if (res.data.fname === null) { // record the referral code event if not yet stored in superAdmin db
                                    // setInvitedBy(`code ${tmpReferral}`)
                                    setShowMessage(false)
                                    setErrorReferral("Referral not found")
                                    setInvitedBy("")
                                    setParrain({})
                                } else {
                                    setShowMessage(true)
                                    setErrorReferral("")
                                    setReferral(tmpReferral)
                                    setInvitedBy(res.data.fname)
                                    setParrain(res.data)
                                    localStorage.setItem('referral', tmpReferral);
                                    setTimeout(() => {
                                        setShowMessage(true)
                                        setErrorReferral("")
                                        setReferral(tmpReferral)
                                        setInvitedBy(res.data.fname)
                                        setParrain(res.data)
                                        localStorage.setItem('referral', tmpReferral);
                                    }, 500);
                                }
                            }
                        }

                        await setData()
                    })
                }
            // }
        } else {
            setErrorReferral("")
            setInvitedBy("")
            setParrain({})
        }
    }

    return (
        <div className="flex flex-col md:flex-row w-full bg-primary-dark overflow-hidden justify-center items-center">
            <div className="flex flex-col justify-center items-center h-full lg:w-1/2 2xl:w-1/3 lg:pl-32 xl:pl-48 p-5 bg-gradient-radial-to-br from-login-first to-transparent z-10">
                <div className="w-full">
                    <Card withBorder>
                        <img src={logo} alt="Logo MLC" className="mt-2 w-1/3 xl:w-1/2 mx-auto"/>
                        <h1 className="text-4xl text-white uppercase text-center mb-6 font-passionOne">Register</h1>
                        <form onSubmit={handleSubmit} className="flex flex-col gap-2" action="">
                            <div className="w-full flex flex-col">
                                <Input type="text"
                                       name="fname"
                                       id="fname"
                                       placeholder="Your firstname"
                                       required="required"
                                       value={fname}
                                       changed={(e) => setFname(stringReplaceDoubleSpaceBySimple(e.target.value))}
                                       onBlur={(event) => setName(event.target.value.trim())}
                                       valid={validFname}
                                       touched={touchedFname}
                                       errorMessage={errorMessageFname}
                                       otherClass={cssFname}
                                       autocomplete="fname"
                                />
                            </div>
                            <div className="w-full flex flex-col">
                                <Input type="text"
                                       name="name"
                                       id="name"
                                       placeholder="Your lastname"
                                       required="required"
                                       value={name}
                                       changed={(e) => setName(stringReplaceDoubleSpaceBySimple(e.target.value))}
                                       onBlur={(event) => setName(event.target.value.trim())}
                                       valid={validName}
                                       touched={touchedName}
                                       errorMessage={errorMessageName}
                                       otherClass={cssName}
                                       autocomplete="lname"
                                />
                            </div>
                            <div className="w-full flex flex-col">
                                <Input type="email"
                                       name="email"
                                       id="email"
                                       placeholder="Your email"
                                       required="required"
                                       changed={(e) => setEmail(stringDeleteSpace(e.target.value))}
                                       valid={validEmail}
                                       touched={touchedEmail}
                                       errorMessage={errorMessageEmail}
                                       otherClass={cssEmail}
                                       autocomplete="email"
                                       value={email}
                                />
                            </div>
                            <div className="w-full flex flex-col">
                                <Input type="text"
                                       name="confirmEmail"
                                       id="emailConfirm"
                                       placeholder="Confirm your email"
                                       required="required"
                                       changed={(e) => setConfirmedEmail(stringDeleteSpace(e.target.value))}
                                       valid={validEmail}
                                       touched={touchedEmail}
                                       errorMessage={errorMessageEmail}
                                       otherClass={cssEmail}
                                    //    onPaste={handleOnPast}
                                       value={confirmedEmail}
                                />
                            </div>
                            {/*<div className="w-full flex flex-col">*/}
                            {/*    <PhoneInput*/}
                            {/*        onChange={phone => setPhone(phone)}*/}
                            {/*        country={'fr'}*/}
                            {/*        inputClass="block w-full rounded border border-gray-300 bg-primary-dark text-xl p-4 text-white font-passionOne"*/}
                            {/*        inputProps={{*/}
                            {/*            name: 'phone',*/}
                            {/*            required: true,*/}
                            {/*            autoFocus: false*/}
                            {/*        }}*/}
                            {/*        inputStyle={{*/}
                            {/*            fontSize: '20px',*/}
                            {/*            lineHeight: '28px',*/}
                            {/*            padding: '24px',*/}
                            {/*            paddingLeft: '60px',*/}
                            {/*            width:'100%',*/}
                            {/*            background:"transparent",*/}
                            {/*        }*/}
                            {/*        }*/}
                            {/*    />*/}
                            {/*    {(<p className="mt-2 text-sm text-red-600" id={'phone-error'}>{errorMessagePhone ? errorMessagePhone : ''}</p>) }*/}
                            {/*</div>*/}
                            <div className="w-full flex flex-col mb-[-5px]">
                                <Input type="password"
                                       value={password}
                                       name="password"
                                       id="password"
                                       placeholder="Your password"
                                       required="required"
                                       changed={(e) => setPassword(stringDeleteSpace(e.target.value))}
                                       valid={validPassword}
                                       touched={touchedPassword}
                                       errorMessage={errorMessagePassword}
                                       otherClass={cssPassword}
                                       autocomplete="off"
                                       after={<div className="px-2"><PasswordStrengthBar password={password} onChangeScore={(score) => setPasswordScore(score)} scoreWords={false} shortScoreWord={false} /></div>}
                                />
                            </div>
                            <div className="w-full flex flex-col">
                                <Input type="password"
                                       value={passwordConf}
                                       name="password_conf"
                                       id="password_conf"
                                       placeholder="Confirm password"
                                       required="required"
                                       changed={(e) => setPasswordConf(stringDeleteSpace(e.target.value))}
                                       valid={validPasswordConf}
                                       touched={touchedPasswordConf}
                                       errorMessage={errorMessagePasswordConf}
                                       otherClass={cssPasswordConf}
                                       autocomplete="off"
                                />
                            </div>

                            {/*<div className="w-full flex flex-col">
                                <div className="flex gap-5 w-full">
                                    <div className="flex w-full relative">
                                        <Input
                                          type="text"
                                          name="referral"
                                          id="referral"
                                          otherClass="w-full rounded font-passionOne text-white mx-auto p-4 text-xl"
                                          placeholder={"Invitation code"}
                                          changed={(e) => handleSaveReferralCode(e.target.value)}
                                          value={referralDisplay}
                                        />
                                        <button type="button" onClick={handleSaveReferralCode} className="absolute bg-primary-orange text-white font-passionOne text-sm rounded p-2 uppercase right-2 top-2">{!savingReferral ? 'Validate' : 'Validating...'}</button>
                                    </div>
                                </div>
                                {showMessage === true && (<p className="mt-2 text-sm text-secondary-green"
                                                             id={'referralName'}>{`You are invited by ${invitedBy}`}</p>)}
                                {!showMessage && errorReferral !== '' && (<p className="mt-2 text-sm text-secondary-red"
                                                                             id={'referralName-error'}>{errorReferral}</p>)}
                            </div>*/}

                            <div className="flex w-full font-passionOne text-white align-middle gap-5 mt-2">
                                <input type="checkbox"
                                       required
                                       className="w-6 h-6 rounded"
                                       onChange={() => setCgv(!cgv)}
                                />
                                <div>
                                    I accept <a href="https://www.mylovelyplanet.org/public-sale-terms-and-conditions"
                                                target="_blank" rel="noreferrer" className="mx-1 text-white underline">the
                                    general terms of sale</a>
                                </div>
                            </div>
                            {/* <div className="w-full flex flex-col my-2">
                                <ReCAPTCHA
                                  sitekey="6Ldn-NAlAAAAAH9Ozb0YUxdAHaEBR7GMZ5lrsHpH"
                                  onChange={handleRecaptcha}
                                />
                                {errorRecaptcha && (
                                  <p className="mt-2 text-sm text-red-600" id={'recaptcha-error'}>{errorRecaptcha}</p>)}
                            </div> */}
                            <div className="flex flex-col w-full">
                                <Button color="secondary" type="submit">
                                    Register
                                </Button>
                            </div>
                        </form>

                        <hr className="bg-login-border border border-login-border mt-3"/>
                        {/* <p className="text-white font-passionOne text-center">Or</p> */}
                        {/* <button
                        type="button"
                        onClick={signInWithGoogle}
                        className="inline-flex justify-center rounded-md bg-white w-full px-4 py-2 text-primary-dark
                             text-xl 2xl:text-2xl font-passionOne mx-auto gap-5"
                    >
                        <img src={googleLogo} className="w-8" alt=""/>
                        Register with Google
                    </button> */}

                        {/* <hr className="bg-login-border border border-login-border"/> */}
                        <div className="flex text-white font-passionOne text-lg text-center mx-auto">
                            <Link key="authLink" to={routes.LOGIN} className="text-center">
                                I already have an account
                            </Link>
                        </div>
                    </Card>
                </div>
            </div>
          <div className='flex flex-col h-full justify-center items-center w-full lg:w-1/2 2xl:w-2/3'>
            <div className='w-full relative'>
                <img src={signin} alt="Background" id="planet-mlp" className="w-full object-contain hidden lg:block"/>
                <img src={signinPlanStar} alt="Background stars"  id="stars-mlp" className="w-full object-contain hidden lg:block absolute"/>
            </div>
          </div>
        </div>
    )
}